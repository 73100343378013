/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    img: "img",
    h3: "h3",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, QuestionAccordion, MapInfo} = _components;
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!QuestionAccordion) _missingMdxReference("QuestionAccordion", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many options available for individuals who may benefit from ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), ". In fact, according to the National Institute on Deafness and Other Communication Disorders, approximately 28.8 million adults in the U.S. could benefit from using a hearing aid."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss can affect people of all ages, and it's encouraging to know that even adults as young as 45 to 54 may benefit from using a hearing aid. Additionally, for those aged 75 and older, a hearing aid can be an especially helpful tool."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Taking action to address hearing loss is important, as studies have shown that untreated hearing loss can lead to a decline in memory and concentration, and even to serious health problems like depression and dementia."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Fortunately, there are many ways to obtain a hearing aid. While working with a licensed healthcare provider like an audiologist or hearing aid dispenser has traditionally been the most common way to obtain a hearing aid, over-the-counter options are now available for people with mild to moderate hearing loss. This can make it easier than ever for individuals to take charge of their hearing health and improve their overall quality of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-hearing-aid-and-how-do-they-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-hearing-aid-and-how-do-they-work",
    "aria-label": "what are hearing aid and how do they work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are Hearing Aid and how do they work?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It's important to know that while hearing loss may be irreversible, hearing aids can make a significant difference in your ability to hear by amplifying sounds and making them easier to understand."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modern digital hearing aids have advanced features like microphones that transmit sound to a computer chip, which then adjusts the volume and amplifies the sound frequencies to improve your hearing. Your hearing healthcare professional can also program your digital hearing aid to filter out background noise and fine-tune it to match your specific hearing loss pattern. Some hearing aids can even sync wirelessly with your smartphone, making it easier to adjust settings and stream audio."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Choosing the right hearing aid depends on factors like the type and severity of your hearing loss, your lifestyle, your manual dexterity and budget. Keep in mind that what works for one person may not work for another, even if they have a similar audiogram."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Choosing the right hearing aid can be challenging, but our buying guide can help you find the right provider and understand what to look for when selecting a hearing aid. With the right hearing aid and support from your healthcare provider, you can improve your hearing and quality of life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-and-apple-watch.png",
    alt: "hearing aids and apple watch",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "otc-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#otc-hearing-aids",
    "aria-label": "otc hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "OTC Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "OTC hearing aids are a type of hearing aid that can be purchased without a prescription or the involvement of a hearing healthcare professional. They are designed to be more affordable and accessible than traditional prescription hearing aids, which require a visit to an audiologist or other hearing healthcare professional for evaluation, fitting, and ongoing adjustments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "OTC hearing aids were made possible by the Over-the-Counter Hearing Aid Act, which was signed into law in 2017 and directed the FDA to create a category of hearing aids that could be sold directly to consumers without a prescription. The goal of the law was to make hearing aids more affordable and accessible to the millions of Americans who need them but have been unable to afford or access traditional hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "OTC hearing aids are typically less expensive than prescription hearing aids, but they may also be less customizable and may not offer the same level of sound quality or performance as prescription hearing aids."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "prescription-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prescription-hearing-aids",
    "aria-label": "prescription hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Prescription Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Prescription hearing aids are hearing aids that are customized and programmed to meet the specific needs of an individual with hearing loss. They are typically prescribed by an audiologist or other hearing healthcare professional after a comprehensive hearing evaluation and assessment of the individual's hearing loss and lifestyle needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Prescription hearing aids are designed to provide a high level of sound quality and performance, and they are typically more customizable and sophisticated than over-the-counter (OTC) hearing aids. They may also include advanced features like noise reduction, speech enhancement, and connectivity to other devices like smartphones and televisions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While prescription hearing aids may be more expensive than OTC hearing aids, they offer a higher level of customization, sound quality, and performance. They may also be covered by insurance or other forms of financial assistance, making them more affordable for some individuals."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "whats-the-difference-between-otc-and-prescription-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#whats-the-difference-between-otc-and-prescription-hearing-aids",
    "aria-label": "whats the difference between otc and prescription hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What’s the difference between OTC and prescription Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-on-table.png",
    alt: "behind the ear hearing aids on table",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here's a table that highlights some of the key differences between the two:"), "\n", React.createElement(QuestionAccordion, {
    question: "Availability"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Over the Counter:"), "\nAvailable for purchase"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Medical Grade:"), "\nMust be prescribed by an audiologist of hearing healthcare professional")), "\n", React.createElement(QuestionAccordion, {
    question: "Customization"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Over the Counter:"), "\nGenerally less customizable"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Medical Grade:"), "\nCustomizable to individual needs and preferences")), "\n", React.createElement(QuestionAccordion, {
    question: "Price"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Over the Counter:"), "\nGenerally less expensive"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Medical Grade:"), "\nCan be more expensive due to customization and professional services")), "\n", React.createElement(QuestionAccordion, {
    question: "Medical Clearance"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Over the Counter:"), "\nNot required"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Medical Grade:"), "\nRequired in some cases")), "\n", React.createElement(QuestionAccordion, {
    question: "Insurance Coverage"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Over the Counter:"), "\nMay not be covered by insurance"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Medical Grade:"), "\nMay be partially of fully covered by insurance")), "\n", React.createElement(QuestionAccordion, {
    question: "Quality Control"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Over the Counter:"), "\nRegulated by FDA"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Medical Grade:"), "\nRegulated by the FDA and subject to higher quality standards")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It's worth noting that OTC hearing aids are a relatively new category of hearing aids that were introduced in 2017 with the passage of the Over-the-Counter Hearing Aid Act. As such, there are currently only a limited number of OTC hearing aids available on the market, and their features and quality may vary widely. Prescription hearing aids, on the other hand, have been around for decades and are subject to rigorous quality standards set by the FDA."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-types",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-types",
    "aria-label": "hearing aid types permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Types"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "receiver-in-canal-ric-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#receiver-in-canal-ric-hearing-aids",
    "aria-label": "receiver in canal ric hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Receiver-in-canal (RIC) hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/behind-the-ears-hearing-aids-on-man.png",
    alt: "behind the ear receiver in canal hearing aid on man",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "Receiver in the Canal (RIC) hearing aids"), " are a type of behind-the-ear (BTE) hearing aid that have a small receiver or speaker that sits inside the ear canal. The hearing aid itself sits behind the ear, with a thin wire connecting the hearing aid to the receiver in the ear canal. RIC hearing aids are designed to amplify sound and improve hearing for people with mild to moderate hearing loss. They are often preferred by people who want a discreet hearing aid that is comfortable to wear and provides natural sound quality. RIC hearing aids may also offer features such as wireless connectivity to smartphones or other devices and noise reduction capabilities."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Pros:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Comfortable fit: RIC hearing aids are comfortable to wear because they are small and lightweight."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Improved sound quality: The receiver sits directly in the ear canal, which provides a more natural sound quality compared to other types of hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Good for mild to moderate hearing loss: RIC hearing aids are typically recommended for people with mild to moderate hearing loss."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Discreet appearance: Because RIC hearing aids are small and fit behind the ear, they are less noticeable than other types of hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Easy to adjust: RIC hearing aids can be adjusted easily by your audiologist."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Cons:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Vulnerable to moisture and wax: The receiver in the ear canal is vulnerable to moisture and wax, which can cause issues with sound quality or even damage the hearing aid."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Cost: RIC hearing aids can be more expensive than other types of hearing aids due to their advanced technology and features."), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "behind-the-ear-bte-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#behind-the-ear-bte-hearing-aids",
    "aria-label": "behind the ear bte hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Behind-the-ear (BTE) hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/behind-the-ear-hearing-aids-type.png",
    alt: "behind the ear hearing aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Behind-the-ear (BTE) hearing aids are a type of hearing aid that sits behind the ear and is connected to a custom earpiece or earmold that sits inside the ear canal. The hearing aid itself contains the microphone, amplifier, and battery, which powers the device. Sound is picked up by the microphone, amplified, and then sent to the earpiece in the ear canal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "BTE hearing aids are versatile and can be used to treat a wide range of hearing losses, from mild to severe. They are often preferred by people who require a more powerful hearing aid or have dexterity issues that make it difficult to handle smaller devices. BTE hearing aids can also offer features such as directional microphones, which can help reduce background noise, and wireless connectivity to smartphones or other devices.\nBTE hearing aids can be made in a variety of colors to blend in with the wearer's hair or skin tone, or they can be customized with designs or patterns for a more personal touch. Some newer models are also more compact and streamlined, making them less noticeable and more comfortable to wear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Pros:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "BTE hearing aids can be used to treat a wide range of hearing losses, from mild to severe."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "They are often preferred by people who require a more powerful hearing aid or have dexterity issues that make it difficult to handle smaller devices."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "BTE hearing aids are more durable and have a longer lifespan compared to other types of hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "They have rechargeable batteries."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "They can offer features such as directional microphones, which can help reduce background noise, and wireless connectivity to smartphones or other devices."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "BTE hearing aids can be made in a variety of colors to blend in with the wearer's hair or skin tone, or they can be customized with designs or patterns for a more personal touch."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Cons:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Vulnerable to moisture and wax: The receiver in the ear canal is vulnerable to moisture and wax, which can cause issues with sound quality or even damage the hearing aid."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Cost: RIC hearing aids can be more expensive than other types of hearing aids due to their advanced technology and features."), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "completely-in-canal-cic-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#completely-in-canal-cic-hearing-aids",
    "aria-label": "completely in canal cic hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Completely-in-canal (CIC) hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/completely-in-canal-hearing-aids.png",
    alt: "completely in canal hearing aid on woman",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/horizon/mini-ix/",
    className: "c-md-a"
  }, "Completely-in-canal (CIC) hearing aids"), " are a type of hearing aid that is custom-made to fit entirely inside the ear canal. These devices are designed to provide a discreet and nearly invisible hearing solution for people with mild to moderate hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "CIC hearing aids are the smallest and most discreet type of hearing aid available on the market. They are custom-fitted to the wearer's ear canal, which makes them very comfortable to wear for extended periods. Because they are located deep within the ear canal, CIC hearing aids provide a natural sound quality and can reduce background noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Pros:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Discreet: CIC hearing aids are the smallest and most discreet type of hearing aid, making them virtually invisible when worn."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Comfortable: These devices are custom-fitted to the wearer's ear canal, which makes them very comfortable to wear for extended periods."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Natural sound quality: Because CIC hearing aids are located deep within the ear canal, they can provide a more natural sound quality and reduce background noise."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "No interference with glasses or hats: Unlike behind-the-ear (BTE) hearing aids, CIC devices do not interfere with the use of glasses or hats."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Less wind noise: CIC hearing aids are less prone to wind noise than other types of hearing aids, which can be helpful for outdoor activities."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Cons:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Limited power: CIC hearing aids are typically smaller and have less power than other types of hearing aids, which may not be sufficient for severe hearing loss."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Limited features: Due to their size, CIC hearing aids may not have as many features as larger hearing aids, such as directional microphones or wireless connectivity."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Earwax buildup: Because they are inserted directly into the ear canal, CIC hearing aids may be more prone to earwax buildup, which can affect their performance."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty for people with dexterity issues: People with dexterity issues, such as arthritis, may find it challenging to insert and remove CIC hearing aids from their ear canal."), "\n"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "in-the-ear-ite-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-the-ear-ite-hearing-aids",
    "aria-label": "in the ear ite hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In-the-ear (ITE) hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/in-the-ear-hearing-aids-type.png",
    alt: "In the ear hearing aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "ITE hearing aids typically have the microphone, amplifier, and receiver all in one unit, which makes them very discreet and easy to use. However, because they are smaller in size, they may not be as powerful as other types of hearing aids and may not be suitable for all types of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Pros:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Discreet: ITE hearing aids are custom-made to fit inside the ear canal or outer ear bowl, making them virtually invisible to others."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Comfortable: These devices are custom-fitted to the wearer's ear, which makes them very comfortable to wear for extended periods."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Convenient: ITE hearing aids are easy to use and do not require any additional devices or components."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Natural sound quality: Because ITE hearing aids are closer to the eardrum, they can provide a more natural sound quality than other types of hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "No interference with glasses or hats: Unlike behind-the-ear (BTE) hearing aids, ITE devices do not interfere with the use of glasses or hats."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Cons:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Limited power: ITE hearing aids are typically smaller and have less power than BTE or receiver-in-canal (RIC) hearing aids, which may not be sufficient for severe hearing loss."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Limited features: Due to their size, ITE hearing aids may not have as many features as larger hearing aids, such as directional microphones or wireless connectivity."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Earwax buildup: Because they are inserted directly into the ear canal, ITE hearing aids may be more prone to earwax buildup, which can affect their performance."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty for people with dexterity issues: People with dexterity issues, such as arthritis, may find it challenging to insert and remove ITE hearing aids from their ear canal."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-features-you-need-to-consider-before-making-a-purchase",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-features-you-need-to-consider-before-making-a-purchase",
    "aria-label": "hearing aid features you need to consider before making a purchase permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Features you need to consider before making a purchase"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/side-by-side-hearing-aids.png",
    alt: "Side by Side Hearing aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When considering purchasing a hearing aid, there are several important features to consider to ensure you get the best device for your individual needs. Here are some of the main features to consider:"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "noise-reduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#noise-reduction",
    "aria-label": "noise reduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Noise Reduction"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many modern hearing aids come with noise reduction features that can help to ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/background-noise-reduction/",
    className: "c-md-a"
  }, "reduce background noise"), " and improve speech recognition in noisy environments."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "directional-microphones",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#directional-microphones",
    "aria-label": "directional microphones permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Directional microphones"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/speech-clarity/",
    className: "c-md-a"
  }, "Directional microphones"), " can help you focus on sounds coming from a particular direction, which can be particularly helpful in noisy environments or when trying to hear someone who is speaking from a distance."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "bluetooth-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bluetooth-connectivity",
    "aria-label": "bluetooth connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bluetooth Connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "hearing aids come with wireless connectivity"), " features that allow you to connect to other devices, such as smartphones or televisions, for a more seamless listening experience."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "smartphone-apps",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#smartphone-apps",
    "aria-label": "smartphone apps permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Smartphone apps"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Recent advancements in hearing aid technology have enabled users to adjust their listening profiles and volume without physically manipulating their hearing aids by utilizing their smartphones. Additionally, some hearing aid manufacturers provide remote adjustments through their company's ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/apps/",
    className: "c-md-a"
  }, "smartphone app"), ", allowing audiologists to connect to the hearing aids and make necessary modifications from a distance."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "battery-life",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#battery-life",
    "aria-label": "battery life permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Battery life"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The battery life of your hearing aid will depend on the type of hearing aid and the level of use. Some devices come with ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/rechargeable/",
    className: "c-md-a"
  }, "rechargeable batteries"), ", while others require disposable batteries."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "level-of-amplification",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#level-of-amplification",
    "aria-label": "level of amplification permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Level of amplification"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The level of amplification required will depend on the severity of your hearing loss. Your hearing healthcare professional can help you determine the appropriate level of amplification needed for your specific needs."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "telecoils",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#telecoils",
    "aria-label": "telecoils permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Telecoils"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Telecoils make it easier to hear when talking on a telecoil-compatible telephone. The telecoil reduces the sounds from your environment and picks up the sounds from the hearing-aid-compatible telephone. Telecoils also pick up signals from public induction loop systems that can be found in some churches and theatres, allowing you to hear a speaker, play or movie better."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "water-resistant",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#water-resistant",
    "aria-label": "water resistant permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Water Resistant"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It's important to note that ", React.createElement(_components.a, {
    href: "/hearing-aids/water-resistant/",
    className: "c-md-a"
  }, "water-resistant hearing aids"), " are not completely waterproof and should not be submerged in water. However, they can withstand exposure to sweat, rain, and other forms of moisture that are commonly encountered in daily life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "selection-your-hearing-aid-provider",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#selection-your-hearing-aid-provider",
    "aria-label": "selection your hearing aid provider permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Selection your hearing aid provider"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/selecting-hearing-aids-provider.png",
    alt: "Selecting your hearing aids provider",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You want to consider the type of provider who helps you pick out your hearing aids. While most hearing loss is irreversible, sometimes it can be the result of a treatable medical condition.  An audiologist can help determine whether you might have an underlying medical problem (such as a bacterial infection, impacted earwax, or a tumor in your ear) that’s causing your hearing loss. A few online tools are also available that can help you figure out if you might need to see a doctor about a possible medical condition."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many audiologists work in private practices (freestanding offices). They may also be on staff at wholesale clubs or at hearing aid stores owned by such manufacturers as hear.com, Oticon, Phonak, ReSound, Starkey, and Widex."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "working-with-a-hearing-care-provider",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#working-with-a-hearing-care-provider",
    "aria-label": "working with a hearing care provider permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Working with a Hearing Care Provider"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When working with a hearing aid provider, it's important to consider several factors to ensure a positive experience and optimal outcomes. Here are some key aspects to look for when selecting and working with a hearing aid provider:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Professional Qualifications")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Choose a provider who is licensed and qualified, such as an audiologist or hearing instrument specialist. Check their credentials, certifications, and years of experience in the field."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Comprehensive Evaluations")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ensure that the provider conducts thorough hearing evaluations to assess your specific hearing needs and determine the degree and type of hearing loss you have. This will help in recommending appropriate hearing aids and personalized solutions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Range of Services")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Consider the range of services offered by the provider. Look for a provider who can perform fittings, adjustments, and follow-up care to ensure that your hearing aids are properly fitted and programmed according to your unique requirements."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Selection of Hearing Aids")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Assess the variety and quality of hearing aids offered by the provider. They should offer a wide range of options from reputable manufacturers, taking into account your preferences, lifestyle, and budget."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Customization and Trial Period")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ensure that the provider offers customization options for your hearing aids, including programming adjustments and personalized settings. Additionally, inquire about a trial period that allows you to test the hearing aids in real-world situations and determine their suitability."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Ongoing Support and Follow-up Care")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Look for a provider who offers ongoing support, follow-up appointments, and maintenance services. Regular check-ups, cleanings, and adjustments are crucial to ensure optimal performance and address any concerns or issues that may arise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Patient Education")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Assess the variety and quality of hearing aids offered by the provider. They should offer a wide range of options from reputable manufacturers, taking into account your preferences, lifestyle, and budget."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Cost and Insurance Coverage")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Discuss the cost of the hearing aids, as well as any potential insurance coverage or financing options available. A reputable provider should be transparent about pricing and help you explore available financial resources."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Positive Reviews and Recommendations")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Consider reading reviews and seeking recommendations from others who have worked with the provider. Positive feedback and testimonials can provide valuable insights into the provider's reputation and customer satisfaction."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remember that finding the right hearing aid provider is essential for a successful hearing aid journey. Take the time to research and consult with different providers to ensure you find someone who meets your needs and provides the necessary expertise and support."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-test",
    "aria-label": "hearing test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Test"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/in-person-hearing-test.png",
    alt: "Selecting your hearing aids provider",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "in-person-hearing-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-person-hearing-test",
    "aria-label": "in person hearing test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In Person Hearing Test"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An in-person hearing test is a comprehensive evaluation of an individual's hearing ability conducted by a qualified audiologist or hearing healthcare professional. It involves a series of tests and assessments to assess various aspects of hearing and identify any hearing loss or related conditions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During an in-person hearing test, the audiologist will typically:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Conduct a case history interview: The audiologist will ask questions about your medical history, any symptoms or concerns you may have, and your exposure to noise or other factors that could impact your hearing."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Perform otoscopy: The audiologist will examine your ear canal and eardrum using a specialized instrument called an otoscope to check for any visible abnormalities or blockages."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Pure-tone audiometry: This is the most common test, where you will wear headphones and listen to tones at different frequencies and volumes. You will be asked to indicate when you can hear each tone, helping the audiologist determine your hearing thresholds."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Speech audiometry: This test evaluates your ability to understand and repeat words or sentences at different volumes. It assesses your speech perception and helps determine how well you can understand speech in different listening environments."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tympanometry: This test measures the movement of your eardrum in response to changes in air pressure. It provides information about the middle ear function and can detect issues such as fluid accumulation or problems with the eardrum or ossicles."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Additional tests: Depending on your specific needs and concerns, the audiologist may conduct further tests, such as otoacoustic emissions (OAE) or auditory brainstem response (ABR) testing, to gather more information about the health of your inner ear or the auditory pathway."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Following the evaluation, the audiologist will discuss the results with you, explain any hearing loss or related conditions identified, and recommend appropriate interventions or treatment options if necessary. They will also provide guidance on hearing aids or other assistive devices, communication strategies, and ongoing support for managing your hearing health."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An in-person hearing test provides a more accurate and comprehensive assessment compared to online or self-administered tests. It allows for personalized care, detailed analysis, and professional guidance from a qualified healthcare provider."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "online-hearing-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#online-hearing-test",
    "aria-label": "online hearing test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Online Hearing Test"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "online hearing test"), " is a self-administered hearing screening that can be taken using a computer, smartphone, or tablet. It is typically designed to provide a preliminary assessment of an individual's hearing ability and to identify if there may be signs of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Online hearing tests generally involve listening to a series of tones or spoken words through headphones or speakers and responding to prompts or questions. The test may assess different frequencies and volumes to determine the individual's hearing thresholds or may evaluate speech perception in noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While online hearing tests can be convenient and accessible, it's important to note that they are not a substitute for a comprehensive hearing evaluation conducted by a qualified audiologist or hearing healthcare professional. They are best suited for initial screening purposes or to raise awareness about potential hearing difficulties."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If an online hearing test indicates potential hearing loss or if you have concerns about your hearing, it is recommended to schedule an in-person evaluation with an audiologist. A professional evaluation can provide a more accurate assessment, determine the type and degree of hearing loss, and offer personalized recommendations for appropriate interventions or treatment options."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "questions-to-ask-when-shopping-for-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#questions-to-ask-when-shopping-for-hearing-aids",
    "aria-label": "questions to ask when shopping for hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Questions to ask when shopping for hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ite-and-bte-hearing-aids.png",
    alt: "In the ear and behind the ear hearing aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When shopping for hearing aids, it's important to ask relevant questions to gather information and make an informed decision. Here are some questions to consider asking when shopping for hearing aids:"), "\n", React.createElement(QuestionAccordion, {
    question: "What type and degree of hearing loss do I have?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Understanding your specific hearing loss will help determine the appropriate type and features of hearing aids for your needs.")), "\n", React.createElement(QuestionAccordion, {
    question: "What styles of hearing aids are available?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Inquire about the various styles (e.g., behind-the-ear, in-the-ear) and their pros and cons to determine which style suits your preferences and lifestyle.")), "\n", React.createElement(QuestionAccordion, {
    question: "What features and technologies are included in the hearing aids?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ask about features like noise reduction, directional microphones, wireless connectivity, and telecoil compatibility to determine which features are most beneficial for your daily activities.")), "\n", React.createElement(QuestionAccordion, {
    question: "Can the hearing aids be customized to my individual needs?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Find out if the provider can program the hearing aids to match your specific hearing requirements and preferences.")), "\n", React.createElement(QuestionAccordion, {
    question: "What is the expected lifespan of the hearing aids?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Understanding the lifespan can give you an idea of when you might need to replace or upgrade your devices.")), "\n", React.createElement(QuestionAccordion, {
    question: "What is the cost of the hearing aids, and what is included in the price?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Inquire about the total cost, including the hearing aids, fitting appointments, follow-up services, warranties, and any additional accessories or support.")), "\n", React.createElement(QuestionAccordion, {
    question: "Are there any trial periods or return policies available?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ask if there is a trial period during which you can test the hearing aids and return them if they are not suitable for you. Clarify the terms and conditions of the trial period.")), "\n", React.createElement(QuestionAccordion, {
    question: "What ongoing support and maintenance services are provided?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Determine what follow-up care, adjustments, cleanings, and maintenance services are offered and how frequently they are included.")), "\n", React.createElement(QuestionAccordion, {
    question: "What is the provider's policy on repairs and warranties?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Inquire about the warranty coverage for the hearing aids and understand the repair process if any issues arise.")), "\n", React.createElement(QuestionAccordion, {
    question: "Does the provider accept insurance, and what are the coverage options?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Check if the provider accepts your insurance, and if so, what portion of the costs may be covered.")), "\n", React.createElement(QuestionAccordion, {
    question: "Can the provider assist with financing options?"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ask if there are financing plans available or if the provider can recommend any external financing options to help make the cost more manageable.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remember to take notes during your discussions with different providers to compare the information later. It's crucial to feel comfortable and confident in your chosen provider, so don't hesitate to ask any additional questions that come to mind."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "brand-new-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#brand-new-hearing-aids",
    "aria-label": "brand new hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Brand New Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brand-new-hearing-aids.png",
    alt: "Brand new hearing aids",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "when-you-pick-them-up",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#when-you-pick-them-up",
    "aria-label": "when you pick them up permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "When you pick them up"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When picking up your brand new hearing aids, it's important to follow certain best practices to ensure a smooth transition and optimal use. Here are some guidelines to keep in mind:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Schedule an appointment:"), " Coordinate with your hearing healthcare provider to schedule a specific time to pick up your hearing aids. This ensures that they are ready for you and that the provider can dedicate sufficient time to assist you."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Bring a companion:"), " Consider bringing a family member or friend to the appointment. They can provide support, help with any discussions or instructions, and offer an additional perspective during the fitting process."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ask questions:"), " Take the opportunity to ask any questions or address any concerns you may have about your new hearing aids. This includes inquiries about usage, maintenance, troubleshooting, and adjusting to the devices."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Get a thorough fitting:"), " Ensure that your hearing aids are properly fitted to your ears. The provider should make adjustments to ensure a comfortable and secure fit, and they should verify that the devices sit correctly in your ear canal or behind your ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Practice insertion and removal:"), " Ask the provider to demonstrate the proper technique for inserting and removing your hearing aids. Practice doing it yourself under their guidance to feel comfortable and confident with the process."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Learn basic maintenance:"), " Understand the basic care and maintenance routine for your hearing aids. This includes cleaning instructions, battery replacement, storage guidelines, and any additional care practices specific to your device."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Understand controls and features:"), " Familiarize yourself with the controls and features of your hearing aids. Learn how to adjust volume, switch programs if available, and use any additional functionality like wireless connectivity or smartphone apps."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Establish a wearing schedule:"), " Start with a recommended wearing schedule as advised by your provider. Gradually increase the wearing time as you adapt to the new hearing experience. Note any challenges or improvements to discuss during follow-up appointments."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Keep follow-up appointments:"), " Schedule and attend any follow-up appointments as recommended by your provider. These appointments allow for fine-tuning, adjustments, and addressing any issues or concerns that may arise during the initial adaptation period."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Stay patient and persistent:"), " Remember that adjusting to hearing aids can take time. Be patient with yourself as you adapt to the new sounds and experiences. If you encounter any difficulties or have questions along the way, don't hesitate to reach out to your hearing healthcare provider for guidance and support."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By following these best practices, you can maximize the benefits of your new hearing aids and ensure a positive experience as you integrate them into your daily life."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "getting-used-to-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getting-used-to-hearing-aids",
    "aria-label": "getting used to hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Getting used to hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Getting used to brand new hearing aids can take time and patience, but there are several strategies you can employ to facilitate the adjustment process. Here are some tips for getting used to your new hearing aids:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Wear them consistently:"), " Consistency is key to adapting to your hearing aids. Wear them as much as possible throughout the day, gradually increasing the wearing time. This allows your brain to acclimate to the amplified sounds and helps you adjust to the new auditory experience."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Start in quieter environments:"), " Begin by wearing your hearing aids in quieter environments, such as at home or in a quiet room. This allows you to get accustomed to hearing everyday sounds without excessive background noise, making it easier to focus on adjusting to the amplification."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Gradually introduce challenging environments:"), " As you become more comfortable, gradually introduce more challenging listening environments, such as social gatherings, restaurants, or public spaces. These situations provide opportunities to adapt to different noise levels and practice speech comprehension in real-world scenarios."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Practice active listening:"), " Make a conscious effort to actively listen and engage with your surroundings. Pay attention to different sounds, voices, and environmental cues. This practice helps train your brain to process and interpret the amplified sounds more effectively."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Use visual cues:"), " In situations where understanding speech is challenging, rely on visual cues to supplement your hearing. Maintain eye contact with speakers, observe their facial expressions and gestures, and use context to aid in comprehension."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Communicate with others:"), " Inform your family, friends, and coworkers about your new hearing aids and ask for their support and patience during the adjustment period. Encourage them to speak clearly and face you when conversing. Openly communicate your needs and preferences to facilitate better communication."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Attend follow-up appointments:"), " Regularly attend follow-up appointments with your hearing healthcare professional. These appointments allow for fine-tuning and adjustments to optimize your hearing aids' settings based on your feedback and experiences."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Practice with different sounds:"), " Engage in activities that expose you to a variety of sounds, such as listening to music, watching movies or TV shows, or engaging in hobbies that involve sound. This helps your brain adapt to different frequencies and types of sounds."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Be patient and persistent:"), " Adjusting to hearing aids is a gradual process, and everyone's experience is unique. Be patient with yourself and persistent in wearing your devices consistently. Give yourself time to adapt and allow your brain to relearn and readjust to the sounds you've been missing."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Seek support and guidance:"), " If you encounter challenges or have questions along the way, don't hesitate to reach out to your hearing healthcare professional for support and guidance. They can offer advice, troubleshooting tips, and additional strategies to help you navigate the adjustment period."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Remember, the more you wear your hearing aids and actively engage with the sounds around you, the faster you will adapt to them. With time and perseverance, you can fully enjoy the benefits of improved hearing and enhanced communication."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To guarantee your best outcome with hearing aids, we developed the industry's first ", React.createElement(_components.a, {
    href: "/about-us/hearing-success-program/",
    className: "c-md-a"
  }, "30-day hearing success program"), " to help your brain adjust. It's our secret sauce and the reason our customers are twice as happy as the industry average. It's been developed with the leading hearing care experts, is based on the latest scientific insights, and includes feedback from thousands of customers. If you run into any issues along the way, you can reach our dedicated experts at ", React.createElement(_components.a, {
    href: "tel:7865202456",
    className: "c-md-a"
  }, "(786)520-2456"), " for additional support."), "\n", React.createElement(MapInfo));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
